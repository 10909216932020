import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ProductFeature from '../../components/ProductFeature/transFeature'
import Breadcrumb from '../../components/Breadcrumb'
import NossosProdutos from '../../components/NossosProdutos'
import produtos from '../../data/ProductFeature'


export default function TransporteF4cil() {

  const features = [
   
    {
        

      textos: [
        "- Localização e informações do transporte em tempo real",
        "- Sem taxas para passageiros ou motoristas",
        "- Total controle do transporte na cidade com cadastro de todos os motoristas,veículos, rotas, preços.",
        "- Dados por rotas, que permitem implementar melhorias de trânsito e transporte para atrair mais consumidores e gerar mais negócios par o municipio.",
        "- Controle de qualidade da frota com pontuação para veículos e motoristas"
      ],
      imagem: "transportefacil.png",
    },
    
  ]
  return(
    <div>
      <Header/>
        <div class="main-content">
          <Breadcrumb title="Transporte F4cil" />
          <a href="#transportef4cil">
          <ProductFeature  features={features} />
         
          <NossosProdutos produtos={produtos} />
          </a>
        </div>
      <Footer/>
    </div>
  )
}
