import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
export default function ProductFeature(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="iq-challanges ">
        <div className="container">
          {/* <div className="row">
              <div className="col-sm-12">
                <div className="title-box">
                  <h2 className="title text-black">{t('productFeature_title')}</h2>
                  <p className="sub-title text-black">{t('productFeature_title_p1')}</p>
                </div>
              </div>
          </div> */}
          <img src={require('../../assets/images/solution/layaut.png')} className="img-fluid " alt=""></img>
        </div>
      </section>
      <h1>Recursos</h1>
      <section className="iq-features product-feature">
    
        <div className="container">
          {props.features.map((item, key) => (
            <div key={key} className="feature1">
            
              <div className="row ">
                  
                <div className="col-md-6 text-center position-relative">
                  <div className="features-img ">
                    <img src={require(`../../assets/images/solution/${item.imagem}`)} className="img-fluid" alt=""></img>
                  </div>
                </div>
                {item.textos.length > 0 ? (
                <div className="col-md-6 text-left align-self-center mt-5 mt-lg-0">
                  <div className="iq-featurebox">
                    <p className="mt-3 text-white">
                      {item.textos.map(text => (
                        <>
                          {t(text)}<br></br>
                        </>
                      ))}
                    </p>
                  </div>
                </div> ) : null }
              </div>
            </div>
          ))}
       
          <div className="row ">
                  
                 
                 
                  <div className="col-md-4 mt-4 mt-lg-0">
                 
                    <div className="iq-featurebox text-center">
                        <h1 style={{color: 'white'}}>Visitar</h1>
                   <a href="https://transportef4cil.vercel.app/" target="_black"> <button  className="btn btn-primary">Transporte F4cil</button>     </a>
                    </div>
                  </div> 

                  <div className="col-md-4 mt-5 mt-lg-0">
                 
                    <div className="iq-featurebox text-center">
                    <a href="https://play.google.com/store/apps/details?id=com.vse.transportef4cil" target="_black"> <img src={require('../../assets/images/download.png')} className="img-fluid " alt=""></img>   
                   </a>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5 mt-lg-0">
                 
                 <div className="iq-featurebox text-center">
                 <a href="https://apps.apple.com/br/app/transporte-f4cil/id1566201521" target="_black"> <img src={require('../../assets/images/appstore.png')} className="img-fluid " alt=""></img>   
                </a>
                 </div>
               </div> 
                </div>
        </div>

      </section>
    </>
  )
}

ProductFeature.propType = {
  features: PropTypes.array.isRequired,
  linkCompra: PropTypes.string.isRequired
}
