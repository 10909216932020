import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ProductFeature from '../../components/ProductFeature/phpontoFeature'
import Breadcrumb from '../../components/Breadcrumb'
import NossosProdutos from '../../components/NossosProdutos'
import produtos from '../../data/ProductFeature'


export default function Phponto() {

  const features = [
    
    {
      textos: [
        "O Ponto F4cil sempre foi reconhecido pelo desempenho, exatidão nas respostas e, primciplmente, pela facilidade de uso. Por isso nesta nova versão decidimos adotar o nome F4cil – Sistema de Ponto. Desenvolvido para atender à Portaria 1510 do Ministério do Trabalho e Emprego, o PHPONTO oferece ao gestor de Recursos Humanos uma excelente ferramenta de gerenciamento do Ponto. Por causa da sua interface (telas) limpa e compreensível, o operador aprende rapidamente todas as funções do software, reduzindo bastante o tempo de assimilação e a necessidade de novos treinamentos. Isto significa economia de tempo e dinheiro.",
      ],
      imagem: "phponto/dashboard.png",
    },
    {
      textos: [
        "Outra grande vantagem do F4cil é o fato de possuir um poderoso banco de dados armazenado em DATACENTER. Isso garante ao Gestor disponibilidade das informações a qualquer tempo e em qualquer lugar, sem correr o risco de perder os dados gravados no próprio computador, como acontece com os sistemas comuns. Diariamente são realizados dois backups e replicados para outros servidores para que você tenha total segurança que seus dados estão protegidos.",
      ],
      imagem: "phponto/horario.png",
    },
    {
      textos: [
        "Principais Recursos",
        "⦁	Ampla política de escalas;",
        "⦁	diversos relatórios gerenciais;",
        "⦁	comunicação com os principais equipamentos de ponto do mercado;",
        "⦁	política de Adicional Noturno flexível (CLT, Súmula 60);",
        "⦁	previsão de uso de vales-transportes;",
        "⦁	sistema de pontuação para efeito de bonificações;",
        "⦁	controle de CID e CRM nos atestados médicos;",
        "⦁	exportação de dados para folha de pagamento*;",
        "⦁	Controle de Banco de Horas;",
        "⦁	Controle de Atestados Médicos;",
        "⦁	Sem limite de cadastros no banco de dados.",
        "⦁	Permite acesso pelo módulo Web",
        "⦁	Permite solicitação de registro de ponto por smartphone e tablets.",
        "⦁	Sem limite de empresas e funcionários"
        ,
      ],
      imagem: "phponto/app.png",
    },
  ]
  return(
    <div>
      <Header/>
        <div class="main-content">
          <Breadcrumb title="Ponto F4cil" />
          <ProductFeature features={features} linkCompra={"https://api.whatsapp.com/send?phone=5521966026707&text=+Olá,+gostaria+de+mais+informações+sobre+o+Ponto+F4cil"} />
          <NossosProdutos produtos={produtos} />
        </div>
      <Footer/>
    </div>
  )
}
