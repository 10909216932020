import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ProductFeature from '../../components/ProductFeature/energiaFeature'
import Breadcrumb from '../../components/Breadcrumb'
import NossosProdutos from '../../components/NossosProdutos'
import produtos from '../../data/ProductFeature'


export default function EnergiaSolar() {

  const features = [
   
    {
      textos: [
        "Reconhecemos a importância de uma energia limpa e renovável, por isso, apresentamos a seguir as principais vantagens para a tomada de decisão em implantar o sistema solar fotovoltaico."
      ],
      imagem: "placa4.png",
    },
    {
        textos: [
          "Energia Limpa"
        ],
        imagem: "placa5.png",
      },
      {
        textos: [
          "Redução significativa no custo da energia elétrica"
        ],
        imagem: "placa2.jpeg",
      },
      {
        textos: [
          "Vida Útil aproximada de 25 anos"
        ],
        imagem: "placa3.jpeg",
      },
      {
        textos: [
          "Sem ajuste tarifário"
        ],
        imagem: "placa1.jpeg",
      },
      {
        textos: [
          "Valorização do Imóvel"
        ],
        imagem: "placa3.jpeg",
      },
  ]
  return(
    <div>
      <Header/>
        <div class="main-content">
          <Breadcrumb title="Energia Solar" />
          <ProductFeature  features={features} />
          <NossosProdutos produtos={produtos} />
        </div>
      <Footer/>
    </div>
  )
}
