import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
export default function ProductFeature(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="iq-challanges ">
        <div className="container">
         
        <p style={{paddingLeft: '10px'}}> A F4cil Soluções, em parceria com o Grupo INVESTSOL, oferece serviços de projetos especializados em energia fotovoltaica. Conta com uma equipe experiente em instalações elétricas treinadas em sistemas fotovoltaicos e com certificados em NR-10 e NR-35, garantindo a qualidade e a segurança das suas instalações. Cuidamos de todo o processo de homologação do seu sistema fotovoltaico, desde a fase de projeto, aprovação junto à concessionária de energia elétrica seguindo todas as normas nacionais para a instalação do sistema (Resolução normativa 482 e 687).</p>
          {/* <div className="row">
              <div className="col-sm-12">
                <div className="title-box">
                  <h2 className="title text-black">{t('productFeature_title')}</h2>
                  <p className="sub-title text-black">{t('productFeature_title_p1')}</p>
                </div>
              </div>
          </div> */}
          {/* <img src={require('../../assets/images/solution/layaut.jpeg')} className="img-fluid " alt=""></img> */}
        </div>
      </section>
      <h1>VANTAGENS</h1>
      <section className="iq-features product-feature">
    
        <div className="container">
          {props.features.map((item, key) => (
            <div key={key} className="feature1">
            
              <div className="row ">
                  
                <div className="col-md-6 text-center position-relative">
                  <div className="features-img ">
                    <img src={require(`../../assets/images/solution/${item.imagem}`)} className="img-fluid" alt=""></img>
                  </div>
                </div>
                {item.textos.length > 0 ? (
                <div className="col-md-6 text-left align-self-center mt-5 mt-lg-0">
                  <div className="iq-featurebox">
                    <p className="mt-3 text-white">
                      {item.textos.map(text => (
                        <>
                          {t(text)}<br></br>
                        </>
                      ))}
                    </p>
                  </div>
                </div> ) : null }
              </div>
            </div>
          ))}
          {/* <div className="row ">
                  
                 
                 
                  <div className="col-md-5 mt-5 mt-lg-0">
                 
                    <div className="iq-featurebox text-center">
                        <h1 style={{color: 'white'}}>Visita</h1>
                   <a href="https://lotacao-nextjs.vercel.app/" target="_black"> <button  className="btn btn-primary">Transporte F4cil</button>     </a>
                    </div>
                  </div> 
                </div> */}
        </div>

      </section>
    </>
  )
}

ProductFeature.propType = {
  features: PropTypes.array.isRequired,
  linkCompra: PropTypes.string.isRequired
}
