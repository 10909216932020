export default  [
  {
    nome: "compact",
    descricao: "compact_p1",
    imagem: "compact-logo.png",
    link: "/compacto"
  },
  {
    nome: "phponto",
    descricao: "phponto_p1",
    imagem: "pontofacil.jpeg",
    link: "/phponto"
  },
  {
    nome: "mensageiro",
    descricao: "mensageiro_p1",
    imagem: "mensageiro-logo.png",
    link: "/mensageiro"
  },
  {
    nome: "polofacil",
    descricao: "polofacil_p1",
    imagem: "polofacil-logo.png",
    link: "/polofacil"
  },
  {
    nome: "Gecert",
    descricao: "gcert_p1",
    imagem: "gcert-logo.jpg",
    link: "/gecert"
  },
  {
    nome: "Transporte F4cil",
    descricao: "Transporte de qualidade. Cidadão satisfeito",
    imagem: "LOGO_TRNSPORTE.png",
    link: "/transportef4cil"
  },
  {
    nome: "Energia Solar",
    descricao: "Não é custo. É investimento com retorno garantido",
    imagem: "LOGO_SOLAR.png",
    link: "/energiasolar"
  }
]