import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
export default function ProductFeature(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      
        <div className="container">
           <div className="row">
              <div className="col-sm-12">
                <div className="title-box">
                  <h3 className="title text-black" style={{fontWeight: 'normal', fontSize: '30px'}}>Baixe aqui o Ponto F4cil e teste grátis por 30 dias!</h3>
                  <br/>
                  <div className="info-box ">
                  
                  <button  className="btn btn-primary" ><a className="text-white"  href="http://visaose.com.br/suporte/PHPontoF4cil.exe" target="_blank">Baixar</a></button>
                </div>
                </div>
                
              </div>
          </div> 
          </div>
        
      
      <section className="iq-features product-feature">
        <div className="container">

          {props.features.map((item, key) => (
            <div key={key} className="feature1">
              <div className="row ">
                <div className="col-md-6 text-center position-relative">
                  <div className="features-img ">
                    <img src={require(`../../assets/images/solution/${item.imagem}`)} className="img-fluid" alt=""></img>
                  </div>
                </div>
                {item.textos.length > 0 ? (
                <div className="col-md-6 text-left align-self-center mt-5 mt-lg-0">
                  <div className="iq-featurebox">
                    <p className="mt-3 text-white">
                      {item.textos.map(text => (
                        <>
                          {t(text)}<br></br>
                        </>
                      ))}
                    </p>
                  </div>
                </div> ) : null }
              </div>
            </div>
          ))}

<div className="row ">
                  
                 
                 
                  <div className="col-md-12 mt-5 mt-lg-0">
                 
                    <div className="iq-featurebox text-center">
                        <h1 style={{color: 'white'}}>Entrar em Contato</h1>
                   <a href="/contato" target="_black"> <button  className="btn btn-primary">E-mail</button>     </a>
                   <a href={props.linkCompra} target="_black"> <button  className="btn btn-primary">Whatsapp</button>     </a>
                    </div>
                  </div> 
                </div>
        </div>
      </section>
    </>
  )
}

ProductFeature.propType = {
  features: PropTypes.array.isRequired,
  linkCompra: PropTypes.string.isRequired
}
