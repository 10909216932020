import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";

import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { recuperarLogin } from "../../redux/actions/userAction";

function Login(props) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");

  async function handleLogin(e) {
    e.preventDefault();
    props.recuperarLogin(email, props.history);
  }

  return (
    <div>
      <Header />
      <div class="main-content">
        <Breadcrumb title="Login" />
        <section class="iq-login-regi">
          <div class="container">
            <div class="row align-items-center d-flex justify-content-center">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <h2>Esqueci a senha</h2>
                <p class="mt-3 mb-4 pr-5">
                  Digite seu e-mail de cadastro abaixo e clique em enviar. Nós
                  lhe enviaremos um e-mail com link para recadastrar sua senha.
                </p>
                {/* <<h5>You can Login With <i class="fa fa-hand-o-down" aria-hidden="true"></i></h5>
                  <ul class="iq-media mt-3">
                    <li><a href="#" class="fb"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" class="gplus"><i class="fab fa-google"></i></a></li>
                    <li><a href="#" class="lkd"><i class="fab fa-linkedin"></i></a></li>
                  </ul>*/}
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 r-mt3">
                {props.success ? <spam>Enviamos um link para seu email com as instruções.</spam> : <div class="iq-login iq-rmt-20">
                  {props.errors && <spam>{props.errors.geral}</spam>}
                  <form>
                    <div class="form-group">
                      {props.errors && <spam>{props.errors.email}</spam>}
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-control email-bg"
                        id="exampleInputEmail1"
                        placeholder="Digite seu email"
                      ></input>
                    </div>
                    <button onClick={handleLogin} class="button">
                      {props.loading && (
                        <Spinner animation="border" variant="light" size="sm" />
                      )}
                      {t("enviar")}
                    </button>
                  </form>
                  <div class="row">
                    <div class="col-sm-6">
                      <Link to="/login" >{t('login')}</Link>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="text-right">
                        <div>Não tem uma conta?</div>
                        <div>
                          {" "}
                          <Link to="/registro">Registre-se agora</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.UI.loading,
  errors: state.UI.errors,
  success: state.UI.success,
});
const mapActionsToProps = {
  recuperarLogin,
};
export default connect(mapStateToProps, mapActionsToProps)(Login);
