import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
export default function ProductFeature(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="iq-features product-feature">
        <div className="container">
          {props.features.map((item, key) => (
            <div key={key} className="feature1">
              <div className="row ">
                <div className="col-md-6 text-center position-relative">
                  <div className="features-img ">
                    <img src={require(`../../assets/images/solution/${item.imagem}`)} className="img-fluid" alt=""></img>
                  </div>
                </div>
                {item.textos.length > 0 ? (
                <div className="col-md-6 text-left align-self-center mt-5 mt-lg-0">
                  <div className="iq-featurebox">
                    <p className="mt-3 text-white">
                      {item.textos.map(text => (
                        <>
                          {t(text)}<br></br>
                        </>
                      ))}
                    </p>
                  </div>
                </div> ) : null }
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

ProductFeature.propType = {
  features: PropTypes.array.isRequired,
  linkCompra: PropTypes.string.isRequired
}
